import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'Home',
        component: () => import('../views/Index')
    },

    {
        path: '/features',
        name: 'Features',
        component: () => import('../views/Index')
    },

    {
        path: '/pricing',
        name: 'Pricing',
        component: () => import('../views/Index')
    },

    {
        path: '/faq',
        name: 'FAQ',
        component: () => import('../views/Index')
    },

    {
        path: '/help',
        name: 'Help',
        component: () => import('../views/Index')
    },

    {
        path: '/fun-facts',
        name: 'FunFacts',
        component: () => import('../views/Index')
    },

    {
        path: '/area51',
        name: 'area-51',
        component: () => import('../views/Area51')
    },

    {
        path: '*',
        component: () => import('../views/PageError')
    },
]


const router = new VueRouter({
    mode: 'history',

    base: process.env.BASE_URL,

    routes,

    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) return savedPosition

        if (to.path && to.path !== '/') {
            const hash = '#' + to.path.substring(1)

            setTimeout(() => {
                const element = document.querySelector(hash)

                element.scrollIntoView({ behavior: 'smooth' })
            }, 100)

            return { el: hash }
        }

        if (to.path && to.path === '/') {
            window.scrollTo({ top: 0, behavior: 'smooth' })
        }

        return { top: 0 }
    }
})

export default router
