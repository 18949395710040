const pageMeta = [
    {
        path: '/',
        title: '',
        description: 'Docwriter is an online documentation platform for people that uses the Markdown language to create human-friendly documentation for projects.',
    },

    {
        path: '/features',
        title: 'Features',
        description: 'Meet our administration panel featuring rich content editing using Markdown with instant preview and WYSIWYG mode. The built-in Media Manager will allow you to upload and organize the media files the way you want.',
    },

    {
        path: '/pricing',
        title: 'Pricing',
        description: 'Check our simple pricing and choose between Free, Business or Custom plans.',
    },

    {
        path: '/faq',
        title: 'FAQ',
        description: 'If you have a Product, Software, a Theory, a Technology, or anything else description of which you want to share with other people or just have it for your own use, then with the help of docwriter.io you can rapidly create (or replace your existing offline documentation) an easy readable online documentation, accessible 24/7.'
    },

    {
        path: '/help',
        title: 'Help',
        description: "Need help? We've got you covered! Check the documentation of docwriter.io in order to learn how to create Projects, Documentations and make use of other handy features.",
    },

    {
        path: '/fun-facts',
        title: 'Fun Facts',
        description: "Check out these fun facts",
    },
]

export function updatePageMeta(path) {
    const meta          = pageMeta.find(item => item.path === path)
    const defaultTitle  = 'Online Documentation and Knowledge Base Tool | Docwriter'
    let fullPageTitle   = defaultTitle

    if (meta) {
        if (path !== '/') {
            fullPageTitle = `${meta.title} - ${defaultTitle}`
        }

        document.title = fullPageTitle
        document.querySelector('meta[property="og:title"]').setAttribute("content", fullPageTitle)
        document.querySelector('meta[name="twitter:title"]').setAttribute("content", fullPageTitle)

        document.querySelector('meta[name="description"]').setAttribute("content", meta.description)
        document.querySelector('meta[property="og:description"]').setAttribute("content", meta.description)
        document.querySelector('meta[name="twitter:description"]').setAttribute("content", meta.description)
    }
}
