import Vue from 'vue'
import App from './App.vue'
import router from './router'
import VueMeta from 'vue-meta'

Vue.config.productionTip = false

import "../public/css/materialdesignicons.min.css";
import 'swiper/swiper-bundle.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

import axios from 'axios';
import VueAgile from 'vue-agile'
import { VueReCaptcha } from 'vue-recaptcha-v3'
import { BootstrapVue } from 'bootstrap-vue'
var VueScrollTo = require('vue-scrollto');
import Scrollspy from 'vue2-scrollspy';
import VueMasonry from 'vue-masonry-css'
import './assets/css/app.css'

Vue.use(VueScrollTo, {
    duration: 3000,
    easing: "ease"
})

Vue.use(BootstrapVue)
Vue.use(Scrollspy)
Vue.use(VueMasonry)
Vue.use(VueReCaptcha, {
    siteKey: process.env.VUE_APP_GOOGLE_RECAPTCHA_SITE_KEY
})
Vue.use(VueAgile)
Vue.use(VueMeta)

axios.defaults.headers.common = {
    'Content-Type': 'application/json',
    'accept': 'application/json',
    'X-Requested-With': 'XMLHttpRequest'
}

window.axios = axios

new Vue({
    router,
    render: h => h(App)
}).$mount('#app')
