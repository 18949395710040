<template>
    <div>
        <router-view />

        <CookieNotice />
    </div>
</template>

<script>
    import CookieNotice from '@/components/CookieNotice';
    import { updatePageMeta } from '@/services/pageService';

    export default {
        components: {
            CookieNotice
        },

        data() {
            return {
                trackingId: process.env.VUE_APP_GOOGLE_ANALYTICS_TRACKING_ID
            }
        },

        metaInfo() {
            return {
                meta: [
                    { name: 'robots', content: this.getRobotsContent() }
                ]
            }
        },

        methods: {
            async hideRecaptchaBadge() {
                await this.$recaptchaLoaded()

                const recaptcha = this.$recaptchaInstance
                recaptcha.hideBadge()
            },

            getRobotsContent() {
                return process.env.VUE_APP_SEARCH_ENGINE_INDEXING_ENABLED === 'true'
                    ? 'index, follow'
                    : 'noindex, nofollow'
            },

            isCookiesAccepted() {
                return true
            },

            async enableGoogleAnalytics() {
                let gtagScript   = document.createElement('script')
                let gtagLink     = document.createElement('script')
                gtagLink.src     = `https://www.googletagmanager.com/gtag/js?id=${this.trackingId}`

                gtagScript.innerHTML = `
                    window.dataLayer = window.dataLayer || [];
                    function gtag(){dataLayer.push(arguments);}
                    gtag('js', new Date());
                    gtag('config', '${this.trackingId}');
                `

                document.head.appendChild(gtagLink)
                document.head.appendChild(gtagScript)
            },
        },

        created() {
            this.hideRecaptchaBadge()

            if (this.trackingId && this.isCookiesAccepted()) {
                this.enableGoogleAnalytics()
            }
        },

        watch:{
            $route (to) {
                updatePageMeta(to.path)
            }
        },
    }
</script>
