<template>
    <div>
        <!-- Cookie notice -->
        <div
            v-if="cookiesNoticeVisible"
            class="dw-cookie-notice">

            <div class="dw-cookie-notice__icon">
                <img
                    class="img-cookies-light"
                    src="../assets/img/cookies.png"
                    :alt="`Docwriter - Cookie consent information (${appName})`"
                    title="Cookies"
                />
            </div>

            <h2 class="dw-cookie-notice__title">
                We use cookies!
            </h2>

            <p class="dw-cookie-notice__description">
                We use cookies to improve your experience.
                By continuing to use this website, you agree to their use.
            </p>

            <div class="dw-cookie-notice__buttons">
                <div @click="saveCookies" class="dw-cookie-notice__btn dw-cookie-notice__btn--primary">
                    I understand
                </div>
            </div>
        </div>

        <!-- Settings menu -->
        <div class="dw-cookie-settings" :class="{ 'show': settingsMenuVisible }">
            <div @click="hideSettingsMenu" class="dw-cookie-settings__close-btn">
                <i class="far fa-times"></i>
            </div>

            <div class="dw-cookie-settings__content-wrapper">
                <h2 class="dw-cookie-settings-title">
                    Cookie settings
                </h2>

                <p class="dw-cookie-settings-description">
                    When you visit any website, it may store or retrieve information on your browser,
                    mostly in the form of cookies. This information might be about you, your preferences
                    or your device and is mostly used to make the site work as you expect it to.
                    The information does not usually directly identify you, but it can give you a more
                    personalized web experience. Because we respect your right to privacy, you can choose
                    not to allow some types of cookies. However, blocking some types of cookies may
                    impact your experience of the site and the services we are able to offer.
                </p>

                <div @click="allowAllCookies" class="dw-cookie-settings-btn btn-allow-all-cookies">
                    Allow All
                </div>

                <!-- Performance Cookies -->
                <div class="dw-cookie-settings-cookie-type">
                    <div class="dw-cookie-settings-cookie-type__title-wrapper">
                        <div class="dw-cookie-settings-cookie-type__title">
                            Performance Cookies
                        </div>

                        <div class="dw-cookie-settings-cookie-type__enabled">
                            <div class="form-check form-switch mb-0 down-2">
                                <input
                                    v-model="performanceCookieEnabled"
                                    class="form-check-input toggle-performance-cookie"
                                    type="checkbox"
                                    checked
                                />
                            </div>
                        </div>
                    </div>

                    <p class="dw-cookie-settings-cookie-type__description">
                        These cookies allow us to count visits and traffic sources so we can measure
                        and improve the performance of our site. They help us to know which pages are the
                        most and least popular and see how visitors move around the site.
                        All information these cookies collect is aggregated and therefore anonymous.
                        If you do not allow these cookies we will not know when you have visited our site,
                        and will not be able to monitor its performance.
                    </p>
                </div>
            </div>

            <div class="dw-cookie-settings-footer">
                <div @click="saveCookies" class="dw-cookie-settings-btn btn-confirm-my-choices">
                    Confirm My Choice
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { appName } from '@/store';

    export default {
        data() {
            return {
                cookiesNoticeVisible: false,
                settingsMenuVisible: false,
                performanceCookieEnabled: true,
                appName: appName,
            }
        },

        methods: {
            hideSettingsMenu() {
                this.settingsMenuVisible = false
            },

            saveCookies() {
                this.setCookie('cookie_consent', {
                    'performance': this.performanceCookieEnabled,
                })

                this.settingsMenuVisible = false
                this.cookiesNoticeVisible = false
            },

            allowAllCookies() {
                this.performanceCookieEnabled = true
                this.saveCookies()
            },

            setCookie(name, value) {
                const date = new Date()
                date.setTime(date.getTime() + (365 * 24 * 60 * 60 * 1000))

                let expires = 'expires=' + date.toUTCString()

                document.cookie = name + '=' + JSON.stringify(value) + ';' + expires + ';path=/';
            },

            isCookiesAccepted() {
                return document.cookie.includes(`cookie_consent=`)
            },

            showCookiesNotice() {
                this.cookiesNoticeVisible = true
            }
        },

        mounted() {
            if (!this.isCookiesAccepted()) {
                this.showCookiesNotice()
            }
        }
    }
</script>
