const navigationLinks = [
    { name: 'Features', url: '/features', target: "_self", },
    { name: 'Pricing', url: '/pricing', target: "_self", },
    { name: 'Faq', url: '/faq', target: "_self", },
    { name: 'Help', url: '/help', target: "_self", },
    { name: 'Fun facts', url: '/fun-facts', target: "_self", },
    { name: 'Log in', url: `//${process.env.VUE_APP_ADMIN_APP_HOST}/login`, target: "_blank", },
    { name: 'Sign up', url: `//${process.env.VUE_APP_ADMIN_APP_HOST}/register`, target: "_blank", },
]

const appName = 'docwriter.io - Markdown based documentation tool'

export {
    navigationLinks,
    appName
}
